import { Fragment } from 'react'
import { ResultReferral } from '../../lib/validators'
import { PageHeading } from '../layout/PageHeading'

import { getPrettyDate } from '../../lib/utils'

interface ResultHeaderProps {
  patientName?: string
  result: ResultReferral
}

export function ResultHeader({ patientName, result }: ResultHeaderProps) {
  return (
    <PageHeading
      title={result?.referral?.testName || 'Results'}
      description={
        <div>
          {patientName && (
            <Fragment>
              {patientName}
              <br />
            </Fragment>
          )}
          {result.referral?.product?.description || ''}
        </div>
      }
    >
      {result.referral && (
        <Fragment>
          <span className="text-gray-semi-dark dark:text-gray-dark">
            Reference:{' '}
          </span>
          <span className="mr-2">{result?.referral.reference}</span>
        </Fragment>
      )}
      {'    '}
      <span className="text-gray-semi-dark dark:text-gray-dark">
        Results date:{' '}
      </span>
      {getPrettyDate(result?.resultDate)}
    </PageHeading>
  )
}
