import { TrashIcon } from '@heroicons/react/24/solid'
import { BiomarkerPreviewItem } from '../../lib/validators'

interface BiomarkerPillProps {
  biomarker: BiomarkerPreviewItem
}
interface PillProps {
  text: string
  editable?: boolean
  onDelete?: () => void
}

export function BiomarkerPill({ biomarker }: BiomarkerPillProps) {
  return <Pill text={biomarker.name}></Pill>
}

export function Pill({ text, onDelete, editable = true }: PillProps) {
  return (
    <div className="px-2 py-1 my-1 mr-1 align-middle items-center inline-flex border-2 border-dark-gray-lighterer/40 cursor-default text-md rounded-md text-dark-gray-light dark:text-white shadow-lg">
      {text}
      {editable && onDelete && (
        <span
          onClick={onDelete}
          className="cursor-pointer ml-2 rounded-full inline-block bg-gray-semi-dark/20 text-blood font-bold p-1"
        >
          <TrashIcon width={12}></TrashIcon>
        </span>
      )}
    </div>
  )
}
