import { Fragment } from 'react'
import { StatusTag } from '../layout/StatusTag'
import { StatusTagColors } from '../../lib/interfaces'

interface TestTurnaroundTime {
  minimumTime: number | null
  maximumTime: number | null
  providerId?: string
  providerName?: string
}
interface TurnaroundTimes {
  turnaroundTimes?: TestTurnaroundTime | null
  showMissingText?: boolean
  hideHeading?: boolean
  headingText?: string
}

function getFormattedTurnaroundTime(
  minimumTime: number | null,
  maximumTime: number | null
): string {
  if (minimumTime === maximumTime) {
    return `At least ${minimumTime} day${minimumTime === 1 ? '' : 's'}`
  }
  if (minimumTime && maximumTime) {
    return `${minimumTime} - ${maximumTime} days`
  }
  return ''
}

export function TestTurnaroundTime({
  turnaroundTimes,
  showMissingText = true,
  hideHeading = false,
  headingText = 'Estimated test turnaround time',
}: TurnaroundTimes) {
  return (
    <Fragment>
      {!hideHeading && <Fragment>{headingText}:</Fragment>}
      <div className={hideHeading === true ? 'inline' : ''}>
        {turnaroundTimes && (
          <StatusTag
            label={getFormattedTurnaroundTime(
              turnaroundTimes.minimumTime,
              turnaroundTimes.maximumTime
            )}
            color={StatusTagColors.GREEN}
          ></StatusTag>
        )}
        {!turnaroundTimes && showMissingText && (
          <span className="font-normal text-sm">
            Unfortunately we cannot provide an estimated time. We are constantly
            improving this data, so please check back soon.
          </span>
        )}
      </div>
    </Fragment>
  )
}
