import { z } from 'zod'
import { authFetch } from '../providers/AuthProvider'
import {
  BiomarkerItem,
  BiomarkerListSchema,
  OrganisationThemeSchema,
  PathologyProvider,
  PathologyProviderListSchema,
  profileAddressDataSchema,
  ReferralSchema,
  ReferralStatusSchemaList,
  ReferralStatusType,
  ResultProfileSchema,
  UserSlim,
  UserSlimSchema,
} from './validators'
import { ReferralStatus } from './types'
import { useState, useEffect, useMemo } from 'react'
import { ReferralForReferenceData } from '../components/pages/admin/AdminReferralsPage'

export const getIssuedReferrals = async (): Promise<ReferralStatusType[]> => {
  // setTimeout(async () => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          referralsByStatus(status:["ISSUED", "PARTIAL_RESULTS"]) {
            uuid
            status
            reference
            profileUuid
            organisationUuid
            testName
            customerName
            createdAt
            resultUuid
          }
        }`,
    }),
  })
  const data = result.data
  console.log('the data is', data.data)
  const parsedData = ReferralStatusSchemaList.parse(data.data.referralsByStatus)
  console.log(parsedData)
  return parsedData
}

export const getPathologyProviders = async (): Promise<PathologyProvider[]> => {
  // setTimeout(async () => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          pathologyProviders {
            id
            name
          }
        }`,
    }),
  })
  const data = result.data
  const parsedData = PathologyProviderListSchema.parse(
    data.data.pathologyProviders
  )
  return parsedData
}

export const usePathologyProviders = (): [PathologyProvider[], boolean] => {
  const [pathologyProviders, setPathologyProviders] = useState<
    PathologyProvider[]
  >([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchData = async () => {
      const providers = await getPathologyProviders()
      setPathologyProviders(providers)
      setLoading(false)
    }

    fetchData()
  }, [])

  const memoizedProviders = useMemo(
    () => pathologyProviders,
    [pathologyProviders]
  )

  return [memoizedProviders, loading]
}

export const biomarkerItemFields = `
            id
            name
            commonName
            suffix
            prefix
            unit
            refIntervalHigh
            refIntervalLow
            description
            refIntervalHighNotes
            refIntervalLowNotes
            refIntervalMidNotes
            valueType`

export const getBiomarkersForReferralUuid = async (
  referralUuid: string
): Promise<BiomarkerItem[]> => {
  // setTimeout(async () => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          biomarkersForReferral(referralUuid:"${referralUuid}") {
            ${biomarkerItemFields}
          }
        }`,
    }),
  })
  const data = result.data
  const parsedData = BiomarkerListSchema.parse(data.data.biomarkersForReferral)
  return parsedData
}

export enum ResultStatusTypes {
  normal,
  abnormal,
}

export const getResultStatus = (
  value?: string,
  refLow?: string,
  refHigh?: string
): ResultStatusTypes => {
  if (refHigh !== undefined && refLow !== undefined && value !== undefined) {
    if (
      parseFloat(value) > parseFloat(refHigh) ||
      parseFloat(value) < parseFloat(refLow)
    ) {
      return ResultStatusTypes.abnormal
    }
    return ResultStatusTypes.normal
  }

  if (refHigh !== undefined && refLow === undefined && value !== undefined) {
    if (parseFloat(value) > parseFloat(refHigh)) {
      return ResultStatusTypes.abnormal
    }
    return ResultStatusTypes.normal
  }

  if (refHigh === undefined && refLow !== undefined && value !== undefined) {
    if (parseFloat(value) < parseFloat(refLow)) {
      return ResultStatusTypes.abnormal
    }
    return ResultStatusTypes.normal
  }
  return ResultStatusTypes.normal
}

export const SearchUsersResponseSchema = z.array(UserSlimSchema)

export const searchUsers = async (
  searchTerm: string
): Promise<Array<UserSlim>> => {
  if (searchTerm.length < 2) {
    // Adding a minimum length constraint to prevent overly broad searches,
    // reducing load and improving the relevance of results.
    return []
  }

  const response = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          searchUsers(input: {
            searchTerm: "${searchTerm}"
          }) {
            uuid,
            firstName,
            lastName,
            email,
          }
        }`,
    }),
  })

  const users = SearchUsersResponseSchema.parse(response.data.data.searchUsers)

  return users
}

const ReferralForTransferSchema = ReferralSchema.pick({
  uuid: true,
  status: true,
  testName: true,
  reference: true,
  customerName: true,
  organisationUuid: true,
})
const ReferralsForTransferSchema = z.array(ReferralForTransferSchema)

export type ReferralForTransfer = z.infer<typeof ReferralForTransferSchema>

export const getReferralsForTransfer = async (
  userUuid: string,
  statuses?: string[]
): Promise<ReferralForTransfer[]> => {
  const response = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
        query referralsForTransfer($userUuid: UUID!, $statuses: [String!]) {
          adminReferrals(input: {
              userUuid: $userUuid,
              statuses: $statuses
          }) {
              uuid
              organisationUuid
              testName
              status
              customerName
              reference
          }
        }`,
      variables: {
        userUuid,
        statuses,
      },
    }),
  })

  return ReferralsForTransferSchema.parse(response.data.data.adminReferrals)
}

export const validTransferStatuses = [
  ReferralStatus.Created,
  ReferralStatus.Issued,
  ReferralStatus.PendingVerification,
]

interface TransferReferralsInput {
  recipientFirstName: string
  recipientEmail: string
  referrals: string[]
  fromUserUuid: string
}

const TransferredReferralsResponseSchema = z.object({
  transferredReferrals: z.array(
    z.object({
      uuid: z.string().uuid(),
      reference: z.string(),
    })
  ),
  totalTransferred: z.number(),
})

export type TransferredReferralsResponse = z.infer<
  typeof TransferredReferralsResponseSchema
>

export const transferReferrals = async ({
  recipientFirstName,
  recipientEmail,
  referrals,
  fromUserUuid,
}: TransferReferralsInput): Promise<TransferredReferralsResponse> => {
  const response = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
        mutation transferReferrals($recipientFirstName: String!, $recipientEmail: String!, $referrals: [UUID!]!, $fromUserUuid: UUID!) {
          adminTransferReferrals(input: {
              recipientFirstName: $recipientFirstName,
              recipientEmail: $recipientEmail
              referrals: $referrals
              fromUserUuid: $fromUserUuid
          }) {
              transferredReferrals {
                uuid
                reference
              }
              totalTransferred
          }
        }`,
      variables: {
        recipientFirstName,
        recipientEmail,
        referrals,
        fromUserUuid,
      },
    }),
  })

  return TransferredReferralsResponseSchema.parse(
    response.data.data.adminTransferReferrals
  )
}

export const ReferralForReferencePerson = ResultProfileSchema.omit({
  age: true,
}).extend({
  address: profileAddressDataSchema,
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string().nullable(),
  phone: z.string().optional(),
})

export const ReferralForReferenceSchema = ReferralSchema.pick({
  uuid: true,
  status: true,
  testName: true,
  reference: true,
  organisationUuid: true,
  profileUuid: true,
}).extend({
  testList: z.array(z.string()).min(1, 'You must select at least one test'),
  person: ReferralForReferencePerson,
  organisation: z
    .object({
      id: z.string(),
      uuid: z.string().uuid(),
      name: z.string(),
      theme: OrganisationThemeSchema,
    })
    .nullable(),
})

export type ReferralForReference = z.infer<typeof ReferralForReferenceSchema>

export const getReferralForReference = async (
  reference: string
): Promise<ReferralForReference> => {
  const response = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
        adminReferralByReference(reference: "${reference}") {
          uuid
          organisation {
            id
            uuid
            name
            theme {
              logo
              logoSquare
              headerBarBackgroundColor
            }
          }
          person {
            address {
              addressLine1
              addressLine2
              city
              state
              country
              postCode
            }
            dob
            email
            firstName
            gender
            lastName
            phone
          }
          testList
          testName
          organisationUuid
          profileUuid
          reference
          status
        }
      }`,
    }),
  })
  if (response.data.errors) {
    throw new Error(response.data.errors.pop().message)
  }

  return ReferralForReferenceSchema.parse(
    response.data.data.adminReferralByReference
  )
}

interface UpdateReferralInput {
  referral: ReferralForReferenceData
}

export const updateReferral = async ({
  referral,
}: UpdateReferralInput): Promise<ReferralForReference> => {
  const response = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
        mutation adminUpdateReferral($input: UpdateReferralInput!) {
          adminUpdateReferral(input: $input) {
            uuid
            organisation {
              id
              uuid
              name
              theme {
                logo
                logoSquare
                headerBarBackgroundColor
              }
            }
            person {
              address {
                addressLine1
                addressLine2
                city
                state
                country
                postCode
              }
              dob
              email
              firstName
              gender
              lastName
              phone
            }
            testList
            testName
            organisationUuid
            profileUuid
            reference
            status
          }
        }`,
      variables: {
        input: referral,
      },
    }),
  })

  if (response.data.errors) {
    throw new Error(response.data.errors.pop().message)
  }
  return ReferralForReferenceSchema.parse(
    response.data.data.adminUpdateReferral
  )
}
