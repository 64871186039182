import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { ProductWithTestsAndBiomarkersSelected } from '../../lib/validators'
import Checkbox from '../form/Checkbox'
import { onChangeInterface } from '../form/TextInputField'
import RoundedSection from '../layout/RoundedSection'
import { TestTurnaroundTime } from '../ui/TestTurnaroundTime'
import { formatMoney } from '../../lib/utils'
import { Tooltip } from 'react-tooltip'
import { Highlight } from '../ui/Highlight'

interface PackageCustomisationItemProps {
  product: ProductWithTestsAndBiomarkersSelected
  onChange?: (data: onChangeInterface) => void
  onInfoClick?: (biomarker: ProductWithTestsAndBiomarkersSelected) => void
  highlight?: string
}

const getTotalBiomakerCount = (
  product: ProductWithTestsAndBiomarkersSelected
): number => {
  const biomarkers: Record<string, string> = {}
  product.tests?.map((t) => {
    t.biomarkers?.map((b) => {
      biomarkers[b.id] = b.id
    })
  })
  return Object.keys(biomarkers).length
}

export function PackageCustomisationItem({
  product,
  onChange,
  onInfoClick,
  highlight,
}: PackageCustomisationItemProps) {
  return (
    <div className={`relative group`}>
      <Checkbox
        id={product.id}
        name={product.id}
        type="checkbox"
        onChange={onChange}
        checked={product.checked}
        className="w-6 h-6 !absolute top-8 right-2 z-50 hidden"
        checkboxLabel={
          <RoundedSection
            size="lg"
            className="relative group !p-0 -mt-6 dark:!bg-dark-gray-lighterer"
          >
            <div className="absolute bottom-2 right-2">
              <InformationCircleIcon
                width={24}
                height={24}
                className="text-white bg-jade/80 hover:bg-jade rounded-full font-bold"
                onClick={(e) => {
                  e.preventDefault()
                  onInfoClick && onInfoClick(product)
                }}
              ></InformationCircleIcon>
            </div>
            <div className="group-has-[:checked]:bg-jade/30 rounded-lg p-4">
              <div>
                <Highlight term={highlight} text={product.name}></Highlight>
              </div>
              <div>
                <b data-tooltip-id="discount-tooltip">
                  {formatMoney(product.price / 100, 'aud')} *
                </b>
                <Tooltip
                  id="discount-tooltip"
                  place="bottom"
                  content="Does not include bulk discounts"
                  className="rounded-lg"
                />
              </div>
              <div className="text-sm mt-2">
                Includes:
                <br />- {product.tests?.length} test
                {product.tests?.length === 1 ? '' : 's'}
                <br />- {getTotalBiomakerCount(product)} biomarkers
              </div>
              <TestTurnaroundTime
                turnaroundTimes={product.turnAroundTimeAverage}
                hideHeading={true}
                showMissingText={false}
              ></TestTurnaroundTime>
              <div className="hidden text-gray-dark text-sm">
                {product.tests?.map((test) => (
                  <span
                    key={`test-${product.id}-biomarker-${test.id}`}
                    className="text-gray-dark text-sm"
                  >
                    <Highlight
                      term={highlight}
                      text={`${test.name}, `}
                    ></Highlight>
                  </span>
                ))}
              </div>
            </div>
          </RoundedSection>
        }
      ></Checkbox>
    </div>
  )
}
