import { StatusTagColors } from '../../lib/interfaces'
import { ExpectedTimes, getExpectedResultTime } from '../../lib/utils'
import { StatusTag } from '../layout/StatusTag'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

interface ExpectedResultsDateProps {
  identifier: string
  startDate: Date
  minimumTime?: number | null
  maximumTime?: number | null
}

const getColorForStatusTag = (timeStatus: ExpectedTimes): StatusTagColors => {
  switch (timeStatus) {
    case ExpectedTimes.Overdue:
      return StatusTagColors.BLOOD
    case ExpectedTimes.WithinExpectedTimeframe:
      return StatusTagColors.GREEN
    case ExpectedTimes.Unknown:
    default:
      return StatusTagColors.GRAY
  }
}

export function ExpectedResultsDate({
  maximumTime,
  minimumTime,
  startDate,
  identifier,
}: ExpectedResultsDateProps) {
  const { status, dayInfoText, infoText } = getExpectedResultTime(
    startDate,
    minimumTime,
    maximumTime
  )

  return (
    <StatusTag
      color={getColorForStatusTag(status)}
      label={
        <div data-tooltip-id={identifier}>
          <div className="font-bold sm:inline-block pr-2">
            Expected results date:
          </div>
          <span>{infoText}</span>
          {dayInfoText && (
            <Tooltip
              id={identifier}
              place="top"
              content={dayInfoText}
              className="rounded-lg"
              float={true}
            />
          )}
        </div>
      }
    ></StatusTag>
  )
}
