import { Transition } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { Fragment, useEffect, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { ThemeInterface } from '../../lib/interfaces'
import { classNames } from '../../lib/utils'
import {
  BiomarkerResult,
  HistoricalBiomarkerResult,
  HistoricalNumericBiomarkerResult,
} from '../../lib/validators'
import { BiomarkerLineChart } from '../charts/BiomarkerLineChart'
import BoundedValueBar from '../result/BoundedValueBar'
import ResultInfoSection from '../result/ResultInfoSection'
import { BiomarkerResultOptimalRange, getTextForUsersReference } from './utils'
import { createPortal } from 'react-dom'
import { Highlight } from '../ui/Highlight'

interface BiomarkerResultRow {
  className?: string
  biomarker: BiomarkerResult
  optimalRanges?: BiomarkerResultOptimalRange[]
  startOpen?: boolean
  historicalData?: HistoricalBiomarkerResult[]
  theme?: ThemeInterface
  highlight?: string
}

export default function BiomarkerResultRow({
  biomarker,
  historicalData,
  theme,
  startOpen = false,
  optimalRanges = [],
  highlight,
}: BiomarkerResultRow) {
  const [expandedOpen, setExpandedOpen] = useState(false)
  const [historicalBiomarkerData, setHistoricalBiomarkerData] = useState<
    HistoricalBiomarkerResult[] | undefined
  >(historicalData)

  useEffect(() => {
    setHistoricalBiomarkerData(historicalData)
  }, [historicalData])

  useEffect(() => {
    setExpandedOpen(startOpen)
  }, [startOpen])
  const baseTransitionClasses =
    'transition-all duration-500 overflow-hidden transform '
  return (
    <Fragment>
      <div
        className="cursor-pointer "
        onClick={() => setExpandedOpen((expandedOpen) => !expandedOpen)}
      >
        <div
          className={classNames(
            'float-right cursor-pointer text-sm hover:underline',
            theme ? `text-[--link-color]` : `text-blood`
          )}
          style={
            theme
              ? ({
                  '--link-color': theme.linkColor,
                } as React.CSSProperties)
              : {}
          }
          key={`view-more-${biomarker.name}`}
        >
          Click to view more
          {expandedOpen ? (
            <ChevronUpIcon
              className="inline-block"
              style={{ marginTop: '-1px' }}
              width={16}
            ></ChevronUpIcon>
          ) : (
            <ChevronDownIcon
              className="inline-block"
              width={16}
              style={{ marginTop: '-1px' }}
            ></ChevronDownIcon>
          )}
        </div>
        <div className="leading-5 text-md font-bold">
          <Highlight term={highlight} text={biomarker.name}></Highlight>
        </div>
        <div className="leading-5 text-md text-black/70 dark:text-gray-dark mb-8">
          {biomarker.prefix && biomarker.unit}
          <span className="text-4xl font-semibold">
            {biomarker.valueType === 'numeric'
              ? biomarker.numericValue
              : biomarker.stringValue}{' '}
          </span>
          {biomarker.suffix && biomarker.unit}
        </div>
        {/* Optimal Ranges - displayed if biomarker is numeric */}
        {biomarker.valueType === 'numeric' && (
          <div className="flex flex-col md:gap-12 gap-6">
            {/*
            Filter out Optimal Ranges that don't have a low or high range defined, since there is nothing
            sensible to display.
            This really only happens for biomarker results massaged into an Optimal Range
            where biomarker.refIntervalLow and biomarker.refIntervalHigh are null.
            User defined Optimal Ranges should always have a low or high range defined.
            */}
            {optimalRanges
              .filter(
                (optimalRange) =>
                  optimalRange.ranges[0].low !== null ||
                  optimalRange.ranges[0].high !== null
              )
              .map((optimalRange, i) => (
                <div
                  key={`${biomarker.id}-${i}`}
                  className="flex flex-col md:flex-row md:items-center gap-y-6 gap-x-6"
                >
                  {/* Icon (Desktop) */}
                  <div className="hidden md:flex items-center md:w-auto">
                    {optimalRange.icon && (
                      <div id={`${biomarker.id}-${i}`} className="w-6 h-6">
                        {optimalRange.icon}
                      </div>
                    )}
                    {/* Tooltip wrapped in portal because without it the tooltip renders underneath the side nav menu */}
                    {createPortal(
                      <Tooltip
                        anchorSelect={`#${biomarker.id}-${i}`}
                        place="top"
                        content={optimalRange.name}
                        className="!z-[9999]"
                      />,
                      document.body
                    )}
                  </div>

                  {/* Icon and Name (Mobile Only) */}
                  <div className="flex md:hidden items-center gap-2">
                    {optimalRange.icon && (
                      <div className="w-6 h-6">{optimalRange.icon}</div>
                    )}
                    <span className="text-sm font-medium break-words">
                      {optimalRange.name}
                    </span>
                  </div>

                  {/* Bounded Value Bar */}
                  <div className="flex-1 text-right">
                    <BoundedValueBar
                      value={biomarker.numericValue}
                      lowerBound={optimalRange.ranges[0].low}
                      upperBound={optimalRange.ranges[0].high}
                    />
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
      <Transition
        show={expandedOpen}
        as={Fragment}
        appear={true}
        enterFrom={`${baseTransitionClasses} scale-95 opacity-0 max-h-0`}
        enterTo={`${baseTransitionClasses} scale-100 opacity-100 max-h-screen`}
        leaveFrom={`${baseTransitionClasses} scale-100 opacity-100 max-h-screen`}
        leaveTo={`${baseTransitionClasses} scale-95 opacity-0 max-h-0`}
      >
        <div className="mt-10">
          <div className="grid grid-cols-12 gap-4">
            <div
              className={`col-span-12 ${biomarker.valueType === 'numeric' ? 'sm:col-span-6' : 'sm:col-span-12'}`}
            >
              <ResultInfoSection title={`What is ${biomarker.commonName}?`}>
                {biomarker.description}
              </ResultInfoSection>
            </div>
            {true && biomarker.valueType === 'numeric' && (
              <div className="col-span-12 sm:col-span-6">
                <ResultInfoSection title="History">
                  <BiomarkerLineChart
                    data={
                      historicalBiomarkerData as HistoricalNumericBiomarkerResult[]
                    }
                  ></BiomarkerLineChart>
                </ResultInfoSection>
              </div>
            )}
            {biomarker.valueType === 'numeric' && (
              <div className="col-span-12 sm:col-span-12">
                <ResultInfoSection title="What your score may indicate">
                  {getTextForUsersReference(biomarker)}
                </ResultInfoSection>
              </div>
            )}
          </div>
        </div>
      </Transition>
    </Fragment>
  )
}
